import React, { useEffect, useState } from "react";
import LogoutModal from "../../logoutModal/LogoutModal";
import { Typeahead } from "react-bootstrap-typeahead";
import { useAuth } from "../../../context/useAuth";
import Button from "react-bootstrap/esm/Button";
import AppointmentModel from "./AppointmentModel";

const ClientComplianceTable = () => {
  const [isExpire, setIsExpire] = useState(false);
  const programId = useAuth().programId;
  const token = useAuth().token;
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const base_url = process.env.REACT_APP_BASE_URL;
  const [client, setClient] = useState("");
  const [actAppData, setActAppData] = useState([]);
  const [expAppData, setExpActAppData] = useState([]);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [individualAppointmentData, setIndividualAppointmentData] = useState(
    []
  );

  const [isCheckedFrequency, setIsCheckFrequency] = useState(false);
  const [isCheckedOccurrence, setIsCheckOccurrence] = useState(false);

  const handleTypeaheadChange = (selected) => {
    setClient(selected);
  };

  const handleOpenEvent = () => {
    setIsOpenModel(false);
  };

  const getClients = async () => {
    try {
      const payload = {
        programId: programId,
      };

      const response = await fetch(`${base_url}/client/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();

      let temp = [];
      await new Promise((resolve, reject) => {
        for (let i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["client_name"]}`);
        }
        resolve(temp);
      });

      setClients(temp);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    var clientID = await new Promise((resolve, reject) => {
      clients.forEach((elem) => {
        if (elem.includes(client[0])) {
          resolve(elem.split(":")[0]);
        }
      });
      resolve(-1);
    });

    const apiUrl = `${base_url}/appointment/client/complianceReport`;
    var payload = {
      clientID: clientID,
      programId: programId,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    };

    fetch(apiUrl, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        let result = await response.json();

        console.log(`result : `, result);

        let actapp = [];

        let expapp = [];

        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 7)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 5)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 18)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 17)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 6)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 22)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 8)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 12)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 13)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 16)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 15)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 14)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 20)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 19)[0]
            ?.Actual_Bookings || 0
        );
        setActAppData(actapp);
        console.log(`app: `, actapp);

        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 7)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 5)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 18)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 17)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 6)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 22)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 8)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 12)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 13)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 16)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 15)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 14)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 20)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 19)[0]
            ?.expected_count || 0
        );

        setExpActAppData(expapp);
        setLoading(false);
      })
      .then((data) => {
        setLoading(false);
      })

      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAppointment = async (appointmentId) => {
    setIsOpenModel(true);
    try {
      const payload = {
        programId: programId,
        appointmentId: appointmentId,
      };

      const response = await fetch(`${base_url}/appointment/getbyId`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      setIndividualAppointmentData(data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <div className="">
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      <h3 className="pt-4">Clients Compliance</h3>
      <hr />

      <div className=" d-flex">
        <form onSubmit={handleSubmit} className="w-50">
          <div className="row mt-2 w-75 mb-3">
            <Typeahead
              className="w-75"
              id="client"
              onChange={handleTypeaheadChange}
              options={clients.map((elem) => elem.split(":")[1])}
              selected={client}
              placeholder="Select a Client"
            />
            {loading ? (
              <div className="" role="status">
                <span className="">Loading...</span>
              </div>
            ) : (
              <button
                type="Submit"
                className="btn btn-primary "
                style={{ width: "100px" }}
              >
                Submit
              </button>
            )}
          </div>
        </form>

        <div className="d-flex gap-4 w-50">
          <div className="d-flex gap-1 align-items-center">
            <input
              id="freq"
              type="checkbox"
              value={isCheckedFrequency}
              onChange={() => setIsCheckFrequency(!isCheckedFrequency)}
            />
            <label id="freq">Check For Appointment Frequency</label>
          </div>

          <div className="d-flex gap-1 align-items-center">
            <input
              id="occur"
              type="checkbox"
              value={isCheckedOccurrence}
              onChange={() => setIsCheckOccurrence(!isCheckedOccurrence)}
            />
            <label id="occur">Check For Time of Occurrence</label>
          </div>
        </div>
      </div>

      <table class="table table-bordered ">
        <thead>
          <tr>
            {isCheckedFrequency && (
              <th className="" style={{ width: "20%" }}>
                Appointment Frequency
              </th>
            )}

            {isCheckedOccurrence && (
              <th className="" style={{ width: "20%" }}>
                Time of Occurrence
              </th>
            )}

            <th className="" style={{ width: "15%" }}>
              Department
            </th>
            <th className="" style={{ width: "15%" }}>
              Subdepartment
            </th>

            <th className="" style={{ width: "15%" }}>
              Actual Bookings
            </th>
            <th className="" style={{ width: "15%" }}>
              Expected Bookings
            </th>
            {/* <th className="w-[200px]">Actual Bookings</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            {isCheckedFrequency && <td>1 per client</td>}

            {isCheckedOccurrence && <td>day 1</td>}
            <td rowspan="5">CVPP</td>
            <td>Medical: Intake</td>

            <td>{actAppData[0]}</td>
            <td>{expAppData[0]}</td>

            {/* <td>
              {appData.length > 0 &&
                (appData[0] === 0
                  ? "No Bookings"
                  : appData[0].split(",").map((items, index) => (
                      <Button
                        onClick={() => handleAppointment(items)}
                        className="m-1 btn btn-light"
                        key={index}
                      >
                        {items}
                      </Button>
                    )))}
            </td> */}
          </tr>
          <tr>
            {isCheckedFrequency && <td>1 per client</td>}

            {isCheckedOccurrence && <td>day 1</td>}

            <td>Psychiatry: Intake</td>

            <td>{actAppData[1]}</td>
            <td>{expAppData[1]}</td>
            {/* <td>
              {actAppData.length > 0 &&
                (appData[1] === 0 ? 0 : appData[1].split(",").length)}
            </td> */}
            {/* <td>
              {appData.length > 0 &&
                (appData[1] === 0
                  ? "No Bookings"
                  : appData[1].split(",").map((items, index) => (
                      <Button
                        onClick={() => handleAppointment(items)}
                        className="m-1 btn btn-light"
                        key={index}
                      >
                        {items}
                      </Button>
                    )))}
            </td> */}
          </tr>
          <tr>
            {isCheckedFrequency && <td>1x/month per client</td>}

            {isCheckedOccurrence && <td>day 30</td>}

            <td>Medical Follow Up</td>

            <td>{actAppData[2]}</td>
            <td>{expAppData[2]}</td>
            {/* <td>
              {appData.length > 0 &&
                (appData[2] === 0 ? 0 : appData[2].split(",").length)}
            </td> */}
            {/* <td>
              {appData.length > 0 &&
                (appData[2] === 0
                  ? "No Bookings"
                  : appData[2].split(",").map((items, index) => (
                      <Button
                        onClick={() => handleAppointment(items)}
                        className="m-1 btn btn-light"
                        key={index}
                      >
                        {items}
                      </Button>
                    )))}
            </td> */}
          </tr>
          <tr>
            {isCheckedFrequency && <td>2x/month per client</td>}

            {isCheckedOccurrence && (
              <td>day 15, day 30, day 45, day 60, day 75, day 90</td>
            )}

            <td>Psychiatry Follow Up</td>
            <td>{actAppData[3]}</td>
            <td>{expAppData[3]}</td>
            {/* <td>
              {appData.length > 0 &&
                (appData[3] === 0 ? 0 : appData[3].split(",").length)}
            </td> */}
            {/* <td>
              {appData.length > 0 &&
                (appData[3] === 0
                  ? "No Bookings"
                  : appData[3].split(",").map((items, index) => (
                      <Button
                        onClick={() => handleAppointment(items)}
                        className="m-1 btn btn-light"
                        key={index}
                      >
                        {items}
                      </Button>
                    )))}
            </td> */}
          </tr>
          <tr>
            {isCheckedFrequency && <td>1x/week per client</td>}

            {isCheckedOccurrence && (
              <td>
                one every 7 days from start date until completion date (day 90)
              </td>
            )}

            <td>Therapy</td>
            <td>{actAppData[4]}</td>
            <td>{expAppData[4]}</td>
            {/* <td>
              {appData.length > 0 &&
                (appData[4] === 0 ? 0 : appData[4].split(",").length)}
            </td> */}
            {/* <td>
              {appData.length > 0 &&
                (appData[4] === 0
                  ? "No Bookings"
                  : appData[4].split(",").map((items, index) => (
                      <Button
                        onClick={() => handleAppointment(items)}
                        className="m-1 btn btn-light"
                        key={index}
                      >
                        {items}
                      </Button>
                    )))}
            </td> */}
          </tr>

          <tr>
            {isCheckedFrequency && <td>1 per client</td>}

            {isCheckedOccurrence && <td>day 1</td>}

            <td rowspan="2">SUD</td>
            <td>Intake</td>
            <td>{actAppData[5]}</td>
            <td>{expAppData[5]}</td>
            {/* <td>
              {appData.length > 0 &&
                (appData[5] === 0 ? 0 : appData[5].split(",").length)}
            </td> */}
            {/* <td>
              {appData.length > 0 &&
                (appData[5] === 0
                  ? "No Bookings"
                  : appData[5].split(",").map((items, index) => (
                      <Button
                        onClick={() => handleAppointment(items)}
                        className="m-1 btn btn-light"
                        key={index}
                      >
                        {items}
                      </Button>
                    )))}
            </td> */}
          </tr>
          <tr>
            {isCheckedFrequency && <td>4x/week per client</td>}

            {isCheckedOccurrence && (
              <td>Monday-Thursday from day 2 to day 90</td>
            )}

            <td>Groups</td>
            <td>{actAppData[6]}</td>
            <td>{expAppData[6]}</td>
            {/* <td>
              {appData.length > 0 &&
                (appData[6] === 0 ? 0 : appData[6].split(",").length)}
            </td> */}
            {/* <td>
              {appData.length > 0 &&
                (appData[6] === 0
                  ? "No Bookings"
                  : appData[6].split(",").map((items, index) => (
                      <Button
                        onClick={() => handleAppointment(items)}
                        className="m-1 btn btn-light"
                        key={index}
                      >
                        {items}
                      </Button>
                    )))}
            </td> */}
          </tr>

          <tr>
            {isCheckedFrequency && <td>4x/week per client</td>}

            {isCheckedOccurrence && (
              <td>Monday-Thursday from day 2 to day 90</td>
            )}

            <td rowspan="2">AOG</td>
            <td>8 to Great Groups</td>
            <td>{actAppData[7]}</td>
            <td>{expAppData[7]}</td>
            {/* <td>
              {appData.length > 0 &&
                (appData[7] === 0 ? 0 : appData[7].split(",").length)}
            </td> */}
            {/* <td>
              {appData.length > 0 &&
                (appData[7] === 0
                  ? "No Bookings"
                  : appData[7].split(",").map((items, index) => (
                      <Button
                        onClick={() => handleAppointment(items)}
                        className="m-1 btn btn-light"
                        key={index}
                      >
                        {items}
                      </Button>
                    )))}
            </td> */}
          </tr>
          <tr>
            {isCheckedFrequency && <td>1x/week per female client</td>}

            {isCheckedOccurrence && (
              <td>every Friday from start of programming to day 90</td>
            )}

            <td>Women's Group</td>
            <td>{actAppData[8]}</td>
            <td>{expAppData[8]}</td>
            {/* <td>
              {appData.length > 0 &&
                (appData[8] === 0 ? 0 : appData[8].split(",").length)}
            </td> */}
            {/* <td>
              {appData.length > 0 &&
                (appData[8] === 0
                  ? "No Bookings"
                  : appData[8].split(",").map((items, index) => (
                      <Button
                        onClick={() => handleAppointment(items)}
                        className="m-1 btn btn-light"
                        key={index}
                      >
                        {items}
                      </Button>
                    )))}
            </td> */}
          </tr>

          <tr>
            {isCheckedFrequency && <td>1x/week per client, 10 in total</td>}

            {isCheckedOccurrence && (
              <td>
                once per week from start date (one every 7 days) until day 90
              </td>
            )}

            <td rowspan="2">Care Coordination</td>
            <td>30/60/90 Checklist</td>
            <td>{actAppData[9]}</td>
            <td>{expAppData[9]}</td>
            {/* <td>
              {appData.length > 0 &&
                (appData[9] === 0 ? 0 : appData[9].split(",").length)}
            </td> */}
            {/* <td>
              {appData.length > 0 &&
                (appData[9] === 0
                  ? "No Bookings"
                  : appData[9].split(",").map((items, index) => (
                      <Button
                        onClick={() => handleAppointment(items)}
                        className="m-1 btn btn-light"
                        key={index}
                      >
                        {items}
                      </Button>
                    )))}
            </td> */}
          </tr>
          <tr>
            {isCheckedFrequency && <td>1 per client</td>}

            {isCheckedOccurrence && <td>1 at day 90</td>}

            <td>PDP</td>
            <td>{actAppData[10]}</td>
            <td>{expAppData[10]}</td>
            {/* <td>
              {appData.length > 0 &&
                (appData[10] === 0 ? 0 : appData[10].split(",").length)}
            </td> */}
            {/* <td>
              {appData.length > 0 &&
                (appData[10] === 0
                  ? "No Bookings"
                  : appData[10].split(",").map((items, index) => (
                      <Button
                        onClick={() => handleAppointment(items)}
                        className="m-1 btn btn-light"
                        key={index}
                      >
                        {items}
                      </Button>
                    )))}
            </td> */}
          </tr>

          <tr>
            {isCheckedFrequency && <td>1 mandatory per client</td>}

            {isCheckedOccurrence && <td>1 at day 14</td>}

            <td>Shiloh Alignment</td>
            <td>Shiloh Alignments</td>
            <td>{actAppData[11]}</td>
            <td>{expAppData[11]}</td>
            {/* <td>
              {appData.length > 0 &&
                (appData[11] === 0 ? 0 : appData[11].split(",").length)}
            </td> */}
            {/* <td>
              {appData.length > 0 &&
                (appData[11] === 0
                  ? "No Bookings"
                  : appData[11].split(",").map((items, index) => (
                      <Button
                        onClick={() => handleAppointment(items)}
                        className="m-1 btn btn-light"
                        key={index}
                      >
                        {items}
                      </Button>
                    )))}
            </td> */}
          </tr>

          <tr>
            {isCheckedFrequency && <td>As necessary</td>}

            {isCheckedOccurrence && (
              <td>
                no specific time of occurrence, just include number of
                appointments booked in report
              </td>
            )}

            <td rowspan="2">Assessors</td>
            <td>Extensions</td>
            <td>{actAppData[12]}</td>
            <td>-</td>
            {/* <td>
              {appData.length > 0 &&
                (appData[12] === 0 ? 0 : appData[12].split(",").length)}
            </td> */}
            {/* <td>
              {appData.length > 0 &&
                (appData[12] === 0
                  ? "No Bookings"
                  : appData[12].split(",").map((items, index) => (
                      <Button
                        onClick={() => handleAppointment(items)}
                        className="m-1 btn btn-light"
                        key={index}
                      >
                        {items}
                      </Button>
                    )))}
            </td> */}
          </tr>
          <tr>
            {isCheckedFrequency && (
              <td>
                no specific time of occurrence, just include number of
                appointments booked in report
              </td>
            )}

            {isCheckedOccurrence && <td>Step Downs</td>}

            <td>Step Downs</td>
            <td>{actAppData[13]}</td>
            <td>-</td>
            {/* <td>
              {appData.length > 0 &&
                (appData[13] === 0 ? 0 : appData[13].split(",").length)}
            </td> */}
            {/* <td>
              {appData.length > 0 &&
                (appData[13] === 0 ? 0 : appData[13].split(",").length)}
            </td> */}
            {/* <td>
              {appData.length > 0 &&
                (appData[13] === 0
                  ? "No Bookings"
                  : appData[13].split(",").map((items, index) => (
                      <Button
                        onClick={() => handleAppointment(items)}
                        className="m-1 btn btn-light"
                        key={index}
                      >
                        {items}
                      </Button>
                    )))}
            </td> */}
          </tr>
        </tbody>
      </table>
      {isOpenModel && (
        <AppointmentModel
          handleOpenEvent={handleOpenEvent}
          isOpen={isOpenModel}
          data={individualAppointmentData}
        />
      )}
    </div>
  );
};

export default ClientComplianceTable;
